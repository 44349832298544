@charset "UTF-8";
::-webkit-scrollbar {
  background: transparent; /* optional: just make scrollbar invisible */
  height: 5px; /* 가로 스크롤바 넓이 */
}

::-webkit-scrollbar-thumb {
  background: rgb(232, 217, 217); /* 스크롤바 색깔 */
}

body {
  width: 100%;
  height: 100vh;
}

*,
body {
  font-family: sans-serif;
}

body {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: 0;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.rgba-black-light {
  background-color: rgba(0, 0, 0, 0.35);
}

.rgba-black-lighter {
  background-color: #c4c2c2;
}

.rgba-black-strong {
  background-color: rgba(0, 0, 0, 0.7);
}

.bgcolor-transparent {
  background-color: transparent !important;
}

.text-primary-light {
  color: rgb(59, 127, 236);
}

.text-yellow-light {
  color: #ecdbaa !important;
}

.text-danger-light {
  color: #da7999 !important;
}

.text-danger-group {
  color: #ea7e50 !important;
}

.text-danger-config {
  color: #f45a5a !important;
}

.text-primary-group {
  color: rgb(107, 107, 244) !important;
}

.bg-primary-light {
  background-color: rgb(59, 127, 236);
}

.bg-warning-light {
  background-color: rgba(250, 226, 177, 0.5);
}

li.tempActive {
  background-color: rgba(184, 154, 64, 0.57);
  border-radius: inherit;
}

.transparent-input {
  background-color: rgba(184, 180, 180, 0.8) !important;
  border: none;
}

.short-title {
  width: 100%; /* 가로 길이 고정*/
  text-overflow: ellipsis; /* 생략 처리 ( ... )*/
  white-space: nowrap; /* 줄바꿈 하지 않고 잘림*/
}

.oneline-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: left;
  vertical-align: middle;
}

#loading-image {
  background: transparent url(../../public/img/loading.gif) no-repeat center center;
  height: 100px;
  width: 100px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 9999992;
}

.show-loading {
  display: block !important;
}

.hide-loading {
  display: none !important;
}

.list-group-item:hover {
  background-color: rgba(182, 227, 231, 0.18);
}

.bg-selected-row {
  background-color: rgba(231, 224, 182, 0.3);
}

.search-results {
  margin: 1rem;
  min-height: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-truncate {
  white-space: normal !important;
}

@media (max-width: 280px) {
  html {
    width: 280px;
    overflow: auto;
  }
}
@media (min-width: 576px) {
  ::-webkit-scrollbar {
    height: 12px; /* 가로 스크롤바 넓이 */
  }
}
@media (min-width: 768px) {
  ::-webkit-scrollbar {
    height: 13px; /* 가로 스크롤바 넓이 */
  }
}
@media (min-width: 992px) {
  ::-webkit-scrollbar {
    height: 14px; /* 가로 스크롤바 넓이 */
  }
}
header .navbar .navbar-nav .nav-item {
  width: 50px;
  font-size: 0.75rem;
}
header .navbar .navbar-nav .nav-item .nav-link {
  text-align: center;
  padding: 0;
}
header .navbar .navbar-nav .nav-item .nav-link > p {
  padding: 0;
  margin: 0;
}
header .navbar .container-fluid ul.nav {
  overflow-x: auto;
}
header .navbar .container-fluid ul.nav li.nav-item {
  padding: 0.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  max-width: 190px;
  margin-bottom: 3px;
}
header .navbar .container-fluid ul.nav li.nav-item a {
  vertical-align: middle;
}
header .navbar .container-fluid ul.nav li.active {
  background-color: rgba(230, 194, 85, 0.57);
  border-radius: inherit;
}
header div div.p-3.flex-grow-1 {
  font-size: 26px;
}
header div div a {
  color: #666;
}

.nav-font-style {
  font-size: larger;
}

mark {
  background-color: #f0e19c;
  color: rgba(168, 18, 18, 0.6823529412);
  padding: 0;
  border-radius: 0;
  font-weight: bold;
}

#root > div > section {
  padding-top: 1.2rem !important;
  padding-bottom: 2rem !important;
  min-height: 50vh;
}
#root > div > section h2 {
  font-weight: bolder;
  text-align: center;
  padding: 1rem !important;
}
#root > div > section h4 {
  padding: 0.8rem;
}
#root > div > section div.search-results > ul > li {
  padding: 0.8rem;
}
#root > div > section div.search-results > ul > li a:hover {
  text-decoration: underline;
}
#root > div > section div.search-results > ul > li:hover {
  background-color: rgba(153, 175, 212, 0.333);
}
#root > div > section div > div > ul > li > svg,
#root > div > section div > div > h3 > span {
  cursor: pointer;
}
#root > div > section div.my-masonry-grid > div > div > h6 > p {
  cursor: pointer;
  padding: 0;
  color: whitesmoke;
}

.fa-center {
  display: flex;
  align-items: center;
}

.faj-center,
.empty-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-box {
  min-height: 200px;
  height: 30vh;
}

/** 빈 화면의 가로/세로 정중앙에 위치 */
.empty-box-full-center {
  height: 100vh !important; /** 빈 화면의 세로길이를 100vh로 설정 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.txt-important {
  font-weight: bold;
}

.txt-important-title {
  color: #f7e79d !important;
  font-weight: bold;
}

.txt-line-through {
  text-decoration: line-through;
}

.top-wide-menu {
  width: 55px !important;
}

.top-lg-wide-menu {
  width: 60px !important;
}

.invalid-box-border {
  border: 2px solid red !important;
}

/* 수정 */
.mark-moccasin {
  background-color: moccasin !important;
}

/* 임시 선택 */
.mark-green {
  background-color: rgb(203, 230, 214) !important;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1rem; /* gutter size offset, gutter size와 반대로 일치해야함! */
  width: auto;
}

.my-masonry-grid_column {
  margin: 0.5rem auto;
  padding-left: 1rem; /* gutter size, 좌우간 간격 */
  background-clip: padding-box;
  /* Style your items */
}
.my-masonry-grid_column div {
  /* change div to reference your elements you put in <Masonry> */
  word-break: break-all;
  margin-bottom: 1rem;
  padding: 0.3rem;
  border: 1px solid #888;
  border-top: 5px solid #999;
  border-radius: 0.3rem;
  box-sizing: border-box;
}
.my-masonry-grid_column div h6 {
  text-align: center;
  background-color: rgba(136, 136, 136, 0.842);
  padding: 0.5rem;
}
.my-masonry-grid_column div h6 a,
.my-masonry-grid_column div h6 span {
  color: #fff;
  cursor: pointer;
}
.my-masonry-grid_column div h6 a:hover,
.my-masonry-grid_column div h6 span:hover {
  font-weight: bold;
}
.my-masonry-grid_column div h6 a:hover {
  color: rgb(239, 225, 26);
}
.my-masonry-grid_column div h6 span:hover {
  color: rgb(146, 246, 152);
}
.my-masonry-grid_column div p {
  padding: 0.4rem;
  margin-bottom: 0;
}
.my-masonry-grid_column div p a {
  color: rgba(0, 0, 0, 0.806);
}
.my-masonry-grid_column div p a del,
.my-masonry-grid_column div p a small {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}
.my-masonry-grid_column div p a:hover {
  color: gray;
}
.my-masonry-grid_column div p svg {
  color: gray;
  cursor: pointer;
}
.my-masonry-grid_column div p svg:hover {
  color: orange;
  font-weight: bold;
}
.my-masonry-grid_column div > p:nth-child(even) {
  background-color: rgba(215, 208, 208, 0.27);
}/*# sourceMappingURL=main.css.map */