#appTitle,
#startGroup,
#root > div.App > section > div > div > div > form > div.mb-2 > div {
  margin-top: 0.4rem;
}

.config-menu-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000;
  padding-bottom: 0;
  margin-bottom: 0;
}
.config-menu-title > small {
  font-size: 0.8rem;
  font-weight: 400;
  color: #999;
}
