#mainWrap {
  // width: 70%;
  border: 0px solid red;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;

  h2 {
    margin: 2.5rem auto;
  }

  #contentList {
    li {
      border: 0px solid red;
      margin: 40px auto;
      text-align: center;

      p {
        font-weight: bolder;
        font-size: 23px;
        margin: 5px auto;
        color: blue;
        line-height: 160%;
      }
    }

    img {
      border: 1px solid gray;
    }
  }

  #menuLinks {
    li {
      list-style: none;
      margin: 5px;
      display: inline-block;
      background-color: orange;
      padding: 10px;
      border-radius: 5px;
      font-size: 16px;

      a {
        color: #fff;
        text-decoration: none;
      }

      a:hover {
        font-weight: bold;
      }
    }
  }

  #bottomLink {
    font-size: 16px;
    background-color: rgba(255, 162, 0, 0.822);
    border: 1px solid orange;
    border-radius: 5px;
    display: inline-block;
    padding: 15px;
    font-weight: bolder;
    margin-bottom: 80px;

    a {
      color: #fff;
      text-decoration: none;
    }
    a:hover {
      opacity: 0.8;
    }
  }

  /* 850px 이하 */
  @media (max-width: 850px) {
    h2 {
      font-size: 20px;
    }

    #contentList {
      li {
        margin: 20px auto;

        img {
          max-width: 90%;
        }

        p {
          font-size: 15px;
        }
      }
    }

    #menuLinks {
      li {
        margin: 2rem auto;
        padding: 5px;
        font-size: 15px;
      }
    }
  }
}
