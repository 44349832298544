/* th는 기본적으로 중앙정렬 */
table th,
table td {
  text-align: center;
  line-height: 200%;
}

/*
table에 style={{ tableLayout: "fixed", width: "100%" }} 필수 설정!
*/
#root table tr > th,
#root table tr > td {
  /* width: 100%;  */
  /* 가로 길이 고정*/
  text-overflow: ellipsis; /* 생략 처리 ( ... )*/
  overflow: hidden;
  white-space: nowrap; /* 줄바꿈 하지 않고 잘림*/
}

/* movieTable */
#movieTable tr > td:nth-child(2),
#movieTable tr > td:nth-child(3),
#movieTable tr > td:nth-child(4) {
  cursor: pointer;
}

/* #companyTable, #employeeTable 폰트 작게 */
#companyTable tr > td:nth-child(1),
#companyTable tr > td:nth-child(2),
#companyTable tr > td:nth-child(3),
#companyTable tr > td:nth-child(4),
#companyTable tr > td:nth-child(5),
#companyTable tr > td:nth-child(7),
#employeeTable tr > td:nth-child(5),
#employeeTable tr > td:nth-child(8),
#linkTable tr > td:nth-child(1),
#linkTable tr > td:nth-child(2),
#linkTable tr > td:nth-child(4) {
  /* cursor: pointer;
  color: rgb(72, 72, 129); */
  font-size: 0.9rem;
}

#dvCompanyInfo {
  padding: 1rem;
  line-height: 220%;
}

/* ================================== */
/* 이하 클래스 */
/* ================================== */

.center-middle {
  /* 가로, 세로 중앙정렬 */
  text-align: center;
  vertical-align: middle;
}

.short-title {
  width: 100%; /* 가로 길이 고정*/
  text-overflow: ellipsis; /* 생략 처리 ( ... )*/
  white-space: nowrap; /* 줄바꿈 하지 않고 잘림*/
}

/* ================================== */
/* 이하 반응형 */
/* ================================== */

@media (max-width: 768px) {
  /* #movieTable 제목, 수정, 삭제 항목을 제외하고 모두 가림 */
  #movieTable tr > th:nth-child(1),
  #movieTable tr > td:nth-child(1),
  #movieTable tr > th:nth-child(2),
  #movieTable tr > td:nth-child(2),
  #movieTable tr > th:nth-child(3),
  #movieTable tr > td:nth-child(3),
  #movieTable tr > th:nth-child(4),
  #movieTable tr > td:nth-child(4),
  #movieTable tr > th:nth-child(6),
  #movieTable tr > td:nth-child(6),
  /* #companyTable */
  #companyTable tr > th:nth-child(1),
  #companyTable tr > td:nth-child(1),
  #companyTable tr > th:nth-child(2),
  #companyTable tr > td:nth-child(2),
  #companyTable tr > th:nth-child(3),
  #companyTable tr > td:nth-child(3),
  #companyTable tr > th:nth-child(4),
  #companyTable tr > td:nth-child(4),
  #companyTable tr > th:nth-child(5),
  #companyTable tr > td:nth-child(5),
  #companyTable tr > th:nth-child(8),
  #companyTable tr > td:nth-child(8),
  /* #linkTable */
  #linkTable tr > th:nth-child(1),
  #linkTable tr > td:nth-child(1),
  #linkTable tr > th:nth-child(2),
  #linkTable tr > td:nth-child(2) {
    display: none;
  }
}
