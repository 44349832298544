// Global
// @import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap");

::-webkit-scrollbar {
  background: transparent; /* optional: just make scrollbar invisible */
  height: 5px; /* 가로 스크롤바 넓이 */
  // width: 10px; /* 세로 스크롤바 넓이 */
}
::-webkit-scrollbar-thumb {
  background: rgb(232, 217, 217); /* 스크롤바 색깔 */
}

$rgba-black-light-color: rgba(0, 0, 0, 0.35);
$rgba-black-strong-color: rgba(0, 0, 0, 0.7);
$rgba-gray-color: rgba(196, 193, 193, 1);
$rgba-gray-dark-color: rgba(196, 193, 193, 0.8);

body {
  width: 100%;
  height: 100vh;
}

*,
body {
  // font-family: "Nanum Gothic", sans-serif;
  font-family: sans-serif;
}

body {
  width: 100%;
  height: 100%;
}

// 모든 포커스 아웃라인 없앰
*:focus {
  outline: 0;
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none !important;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.rgba-black-light {
  background-color: $rgba-black-light-color;
}

.rgba-black-lighter {
  background-color: #c4c2c2;
}

.rgba-black-strong {
  background-color: $rgba-black-strong-color;
}

.bgcolor-transparent {
  background-color: transparent !important;
}

.text-primary-light {
  color: rgb(59, 127, 236);
}

.text-yellow-light {
  color: #ecdbaa !important;
}

.text-danger-light {
  color: #da7999 !important;
}

.text-danger-group {
  color: #ea7e50 !important;
}

.text-danger-config {
  color: #f45a5a !important;
}

.text-primary-group {
  color: rgb(107, 107, 244) !important;
}

.bg-primary-light {
  background-color: rgb(59, 127, 236);
}

.bg-warning-light {
  background-color: rgb(250, 226, 177, 0.5);
}

li.tempActive {
  background-color: rgba(184, 154, 64, 0.57);
  border-radius: inherit;
}

.transparent-input {
  background-color: darken(
    $color: $rgba-gray-dark-color,
    $amount: 5
  ) !important;
  border: none;
}

// 자동으로 잘림
.short-title {
  width: 100%; /* 가로 길이 고정*/
  text-overflow: ellipsis; /* 생략 처리 ( ... )*/
  white-space: nowrap; /* 줄바꿈 하지 않고 잘림*/
  // overflow: hidden; /* 스크롤 처리 하지 않음*/
}

// 설정된 한줄 전체 넓이로 잘림
.oneline-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100%);
  text-align: left;
  vertical-align: middle;
}

#loading-image {
  background: transparent url(../../public/img/loading.gif) no-repeat center
    center;
  height: 100px;
  width: 100px;
  position: fixed;
  top: calc(100vh / 2);
  left: calc(100vw / 2);
  transform: translate(-50%, -50%);
  z-index: 9999992;
}

.show-loading {
  display: block !important;
}

.hide-loading {
  display: none !important;
}

.list-group-item:hover {
  background-color: rgba(182, 227, 231, 0.18);
}

.bg-selected-row {
  background-color: rgba(231, 224, 182, 0.3);
}

.search-results {
  margin: 1rem;
  min-height: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-truncate {
  white-space: normal !important;
}

// 갤럭시 폴더
@media (max-width: 280px) {
  html {
    width: 280px;
    overflow: auto;
  }
}

@media (min-width: 576px) {
  ::-webkit-scrollbar {
    height: 12px; /* 가로 스크롤바 넓이 */
  }
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    height: 13px; /* 가로 스크롤바 넓이 */
  }
}

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    height: 14px; /* 가로 스크롤바 넓이 */
  }
}
