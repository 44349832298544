mark {
  background-color: #f0e19c;
  color: #a81212ae;
  padding: 0;
  border-radius: 0;
  font-weight: bold;
}
#root > div > section {
  padding-top: 1.2rem !important;
  //padding-top: 2rem;
  padding-bottom: 2rem !important;
  min-height: 50vh;
  h2 {
    font-weight: bolder;
    // margin-bottom: 2rem;
    text-align: center;
    padding: 1rem !important;
  }
  h4 {
    padding: 0.8rem;
  }
  div.search-results > ul > li {
    padding: 0.8rem;
    // a:nth-child(1) {
    //   color: rgb(18, 60, 107);
    // }
    // a:nth-child(2) {
    //   color: rgb(180, 46, 46);
    // }
    // a:nth-child(3) {
    //   color: rgb(53, 66, 182);
    //   font-weight: bold;
    //   span {
    //     color: rgb(58, 59, 68);
    //     font-weight: lighter;
    //   }
    // }
    a:hover {
      // font-weight: bold;
      text-decoration: underline;
    }
  }
  div.search-results > ul > li:hover {
    background-color: rgba(153, 175, 212, 0.333);
  }
  div > div > ul > li > svg,
  div > div > h3 > span {
    cursor: pointer;
  }
  div.my-masonry-grid > div > div > h6 > p {
    cursor: pointer;
    padding: 0;
    color: whitesmoke;
  }
}

.fa-center {
  display: flex;
  align-items: center; // 세로중앙
}

.faj-center,
.empty-box {
  display: flex;
  align-items: center; // 세로중앙
  justify-content: center; // 가로중앙
  // flex-direction: column !important; // 세로정렬
}
.empty-box {
  min-height: 200px;
  height: 30vh;
}

/** 빈 화면의 가로/세로 정중앙에 위치 */
.empty-box-full-center {
  height: 100vh !important; /** 빈 화면의 세로길이를 100vh로 설정 */
  display: flex;
  align-items: center; // 세로중앙
  justify-content: center; // 가로중앙
}

.txt-important {
  // color: #f58658 !important;
  font-weight: bold;
}

.txt-important-title {
  color: #f7e79d !important;
  font-weight: bold;
}

.txt-line-through {
  text-decoration: line-through;
}

.top-wide-menu {
  width: 55px !important;
}

.top-lg-wide-menu {
  width: 60px !important;
}

.invalid-box-border {
  border: 2px solid red !important;
}

/* 수정 */
.mark-moccasin { background-color: moccasin !important; }

/* 임시 선택 */
.mark-green { background-color: rgb(203, 230, 214) !important; }

// .react-colorful__pointer {
//   top: 0 !important;
//   left: 50% !important;
// }