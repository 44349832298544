// Navbar
header {
  .navbar {
    // .navbar-toggler-icon {
    //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    // }
    .navbar-nav {
      .nav-item {
        width: 50px;
        font-size: 0.75rem;
        .nav-link {
          text-align: center;
          padding: 0;
        }
        .nav-link > p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .container-fluid {
      ul.nav {
        overflow-x: auto;
        li.nav-item {
          // display: flex; // 적용 시 넓이 깨짐
          // cursor: pointer;
          padding: 0.5em;
          padding-left: 0.8em;
          padding-right: 0.8em;
          // width: auto;
          // min-width: 90px;
          max-width: 190px;
          // overflow: hidden;
          // text-align: left;
          margin-bottom: 3px;
          a {
            // margin: auto;
            // padding: auto;
            vertical-align: middle;
          }
        }
        li.active {
          background-color: rgb(230 194 85 / 57%);
          border-radius: inherit;
        }
      }
    }
  }
  div {
    div.p-3.flex-grow-1 {
      font-size: 26px;
    }
    div {
      a {
        color: #666;
      }
    }
  }
}

.nav-font-style{
  font-size: larger;
}